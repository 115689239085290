import React from 'react';
import '../../App.css';
import Hero from '../Hero';
import Cards from '../Cards';

function Home() {
    return (
        <>
            <Hero />
            <Cards />
        </>
    )
}

export default Home;